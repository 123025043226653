@if (rootElement) {
    <div class="vz-layout-tpl layout flex">
        <ng-container [ngTemplateOutlet]="tplElement" [ngTemplateOutletContext]="{ $implicit: rootElement }" />
    </div>
}

<ng-template let-tpl #tplElement>
    @switch (tpl.type) {
        @case (LET.Box) {
            <div class="vz-tpl-box" [ngClass]="tpl._classes" [ngStyle]="tpl._styles">
                @if (tpl.items?.length) {
                    @for (item of tpl.items; track $index) {
                        <ng-container [ngTemplateOutlet]="tplElement" [ngTemplateOutletContext]="{ $implicit: item }" />
                    }
                }
            </div>
        }
        @case (LET.Icon) {
            <i [ngClass]="tpl._classes" [ngStyle]="tpl._styles"></i>
        }
        @case (LET.TaskField) {
            @switch (tpl.subType) {
                @case (TFT.ShortId) {
                    <vz-task-id-badge [task]="templateData?.task" [ngClass]="tpl._classes" [ngStyle]="tpl._styles" />
                }
                @case (TFT.Subj) {
                    <div class="elli-wrap" style="margin-bottom: 0;"
                        [ngClass]="tpl._classes"
                        [ngStyle]="tpl._styles">
                        <div
                            nz-typography
                            [nzEllipsis]="!!tpl.maxRows"
                            [nzEllipsisRows]="tpl.maxRows ? tpl.maxRows : undefined"
                            [vz-task-text-classes]="templateData?.task">
                            {{ templateData?.task?.subj }}
                        </div>
                    </div>
                }
                @case (TFT.Creator) {
                    <vz-avatar
                        [ngClass]="tpl._classes"
                        [ngStyle]="tpl._styles"
                        [uid]="templateData?.task?.createdBy"
                        [type]="'user'"
                        [noImg]="tpl.userMode == 'text'"
                        [withName]="tpl.userMode != 'icon'"
                        [size]="tpl?.size || 32" />
                }
                @case (TFT.Assignee) {
                    <vz-avatar
                        [ngClass]="tpl._classes"
                        [ngStyle]="tpl._styles"
                        [uid]="templateData?.task?.assignee || templateData?.task?.assigneeGroup"
                        [type]="!templateData?.task?.assignee && templateData?.task?.assigneeGroup ? 'group' : 'user'"
                        [noImg]="tpl.userMode == 'text'"
                        [withName]="tpl.userMode != 'icon'"
                        [size]="tpl?.size || 32" />
                }
                @case (TFT.Status) {
                    <!-- <div class="layout center" [ngClass]="tpl._classes" [ngStyle]="tpl._styles">
                        <i [ngClass]="$any(TSI)[templateData?.task?.status!] + ' ' + $any(TSC)[templateData?.task?.status!]"></i>
                        @if (!mobile) {
                            <b [ngClass]="$any(TSC)[templateData?.task?.status!]">{{ $any(TSN)[templateData?.task?.status!] }}</b>
                        }
                    </div> -->
                }
                @case (TFT.Created) {
                    <vz-dt [dt]="templateData?.task?.created" />
                }
                @case (TFT.Favorite) {
                    <vz-task-favorite [task]="templateData?.task" [ngClass]="tpl._classes" [ngStyle]="tpl._styles" />
                }
                @case (TFT.Tags) {
                    @if (templateData?.task?.tags?.length) {
                        @for (tid of templateData?.task?.tags; track tid) {
                            <vz-tag [ngClass]="tpl._classes" [ngStyle]="tpl._styles" [tagId]="tid" [tagSize]="tpl.vzSize" />
                        }
                    }
                }
                @case (TFT.Priority) {
                    <div class="layout" [ngClass]="tpl._classes" [ngStyle]="tpl._styles">
                        @switch (tpl.priority) {
                            @case ('square') {
                                <div class="txt-inv p05"
                                    style="padding: 0 0 0 6px; height: 19px; width: 19px; line-height: 1.3;"
                                    [ngClass]="'task-pr-bg-' + templateData?.task?.priority">
                                    {{ templateData?.task?.priority }}
                                </div>
                            }
                            @default {
                                <i [ngClass]="'task-flag-' + templateData?.task?.priority" [title]="'Приоритет: ' + TPN[templateData?.task?.priority || 0]" style="margin-left: 4px;"></i>
                            }
                        }
                    </div>
                }
                @case (TFT.Duedate) {
                    @if (templateData?.task?.dueDate) {
                        <small class="txt-l"
                            [ngClass]="tpl._classes"
                            [ngStyle]="tpl._styles"
                            [vz-duedate-text-classes]="templateData?.task">
                            @if (!tpl.noTitle) {
                                Срок:
                            }
                            {{ templateData?.task?.dueDate | date:'dd.MM.yyyy' }}
                        </small>
                    }
                }
                @case (TFT.Unread) {
                    @if (!templateData?.task?.flags?.seen) {
                        <i class="vzi-record anim-pulse txt-ok" [ngClass]="tpl._classes" [ngStyle]="tpl._styles"></i>
                    }
                }
                @case (TFT.Archived) {
                    @if (templateData?.task?.archived) {
                        <i class="vzi-done" [ngClass]="tpl._classes" [ngStyle]="tpl._styles"></i>
                    }
                }
                @default {
                    <span>UE: {{ tpl | json }}</span>
                }
            }
        }
        @default {
            <span>UE: {{ tpl | json }}</span>
        }
    }
</ng-template>
